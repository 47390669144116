import urlJoin from 'url-join';
import {keys} from 'lodash';
import {CDN_FILE_URL, DATA_TEMPLATE_VERSION} from 'constants/environment';

// // if file is object its version is 2 if not its version is 3
// const templatePath = (version) =>
//   version === 3
//     ? urlJoin(CDN_FILE_URL, 'assets/t', DATA_TEMPLATE_VERSION_3)
//     : urlJoin(CDN_FILE_URL, 'assets/t', DATA_TEMPLATE_VERSION_2);

function buildTemplateURL(filenames) {
  let objFiles = {};

  keys(filenames).forEach((key) => {
    objFiles[key] = urlJoin(
      CDN_FILE_URL,
      'assets/t',
      DATA_TEMPLATE_VERSION,
      filenames[key]
    );
  });

  return objFiles;
}

const EXCEL_DATA_TEMPLATE = {
  ab_cbs_excel: 'ukas_cbs.xlsx',
  ab_certs_excel: 'ab_certs.xlsx',
  cb_certs_excel: 'cb_certs.xlsx',
};

const EXCEL_DATA_SAMPLE = {
  ab_cbs_excel_sample: 'ukas_cbs_sample.xlsx',
  ab_certs_excel_sample: 'ab_certs_sample.xlsx',
  cb_certs_excel_sample: 'cb_certs_sample.xlsx',
};

const EXCEL_DATA_INSTRUCTION = {
  ab_cbs_excel_instruction: 'ukas_cbs_excel_instructions.xlsx',
  ab_certs_excel_instruction: 'ab_certs_excel_instructions.xlsx',
  cb_certs_excel_instruction: 'cb_certs_excel_instructions.xlsx',
};

const XML_DATA_TEMPLATE = {
  ab_cbs_xml: 'ukas_cbs.xml',
  ab_certs_xml: 'ab_certs.xml',
  cb_certs_xml: 'cb_certs.xml',
  mncb_certs_xml: 'mncb_certs.xml',
};

const XML_DATA_SAMPLE = {
  ab_cbs_xml_sample: 'ukas_cbs_sample.xml',
  ab_certs_xml_sample: 'ab_certs_sample.xml',
  cb_certs_xml_sample: 'cb_certs_sample.xml',
  mncb_certs_xml_sample: 'mncb_certs_sample.xml',
};

const XML_DATA_INSTRUCTION = {
  ab_cbs_xml_instruction: 'ukas_cbs_xml_instructions.xlsx',
  ab_certs_xml_instruction: 'ab_certs_xml_instructions.xlsx',
  cb_certs_xml_instruction: 'cb_certs_xml_instructions.xlsx',
};

export const DATA_MANAGEMENT_FILES = {
  ...buildTemplateURL(EXCEL_DATA_TEMPLATE),
  ...buildTemplateURL(EXCEL_DATA_SAMPLE),
  ...buildTemplateURL(EXCEL_DATA_INSTRUCTION),
  ...buildTemplateURL(XML_DATA_TEMPLATE),
  ...buildTemplateURL(XML_DATA_SAMPLE),
  ...buildTemplateURL(XML_DATA_INSTRUCTION),
};

export const CERTCHECK_STATEMENT_FILE = 'CertCheck_Security_Statement.pdf';
